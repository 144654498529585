import { __decorate, __extends } from "tslib";
import { Injectable } from '@angular/core';
import { AwesomeCordovaNativePlugin, cordova } from '@awesome-cordova-plugins/core';
import * as i0 from "@angular/core";
var Insomnia = /** @class */function (_super) {
  __extends(Insomnia, _super);
  function Insomnia() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  Insomnia.prototype.keepAwake = function () {
    return cordova(this, "keepAwake", {}, arguments);
  };
  Insomnia.prototype.allowSleepAgain = function () {
    return cordova(this, "allowSleepAgain", {}, arguments);
  };
  Insomnia.ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "12.2.17",
    ngImport: i0,
    type: Insomnia,
    deps: null,
    target: i0.ɵɵFactoryTarget.Injectable
  });
  Insomnia.ɵprov = i0.ɵɵngDeclareInjectable({
    minVersion: "12.0.0",
    version: "12.2.17",
    ngImport: i0,
    type: Insomnia
  });
  Insomnia.pluginName = "Insomnia";
  Insomnia.plugin = "cordova-plugin-insomnia";
  Insomnia.pluginRef = "plugins.insomnia";
  Insomnia.repo = "https://github.com/EddyVerbruggen/Insomnia-PhoneGap-Plugin";
  Insomnia.platforms = ["Android", "Browser", "Firefox OS", "iOS", "Windows", "Windows Phone 8"];
  Insomnia = __decorate([], Insomnia);
  return Insomnia;
}(AwesomeCordovaNativePlugin);
export { Insomnia };
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.17",
  ngImport: i0,
  type: Insomnia,
  decorators: [{
    type: Injectable
  }],
  propDecorators: {
    keepAwake: [],
    allowSleepAgain: []
  }
});
