import { __decorate, __extends, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { AwesomeCordovaNativePlugin, cordovaInstance, cordova, checkAvailability } from '@awesome-cordova-plugins/core';
import { Observable } from 'rxjs';
import * as i0 from "@angular/core";
var Push = /** @class */function (_super) {
  __extends(Push, _super);
  function Push() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  /**
   * Init push notifications
   *
   * @param options {PushOptions}
   * @returns {PushObject}
   */
  Push.prototype.init = function (options) {
    return new PushObject(options);
  };
  Push.prototype.hasPermission = function () {
    return cordova(this, "hasPermission", {}, arguments);
  };
  Push.prototype.createChannel = function (channel) {
    return cordova(this, "createChannel", {
      "callbackOrder": "reverse"
    }, arguments);
  };
  Push.prototype.deleteChannel = function (id) {
    return cordova(this, "deleteChannel", {
      "callbackOrder": "reverse"
    }, arguments);
  };
  Push.prototype.listChannels = function () {
    return cordova(this, "listChannels", {}, arguments);
  };
  Push.ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "12.2.17",
    ngImport: i0,
    type: Push,
    deps: null,
    target: i0.ɵɵFactoryTarget.Injectable
  });
  Push.ɵprov = i0.ɵɵngDeclareInjectable({
    minVersion: "12.0.0",
    version: "12.2.17",
    ngImport: i0,
    type: Push
  });
  Push.pluginName = "Push";
  Push.plugin = "phonegap-plugin-push";
  Push.pluginRef = "PushNotification";
  Push.repo = "https://github.com/phonegap/phonegap-plugin-push";
  Push.install = "ionic cordova plugin add phonegap-plugin-push";
  Push.platforms = ["Android", "Browser", "iOS", "Windows"];
  Push = __decorate([], Push);
  return Push;
}(AwesomeCordovaNativePlugin);
export { Push };
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.17",
  ngImport: i0,
  type: Push,
  decorators: [{
    type: Injectable
  }],
  propDecorators: {
    hasPermission: [],
    createChannel: [],
    deleteChannel: [],
    listChannels: []
  }
});
var PushObject = /** @class */function () {
  function PushObject(options) {
    if (checkAvailability('PushNotification', 'init', 'PushNotification') === true) {
      if (typeof window !== 'undefined') {
        this._objectInstance = window.PushNotification.init(options);
      }
    }
  }
  PushObject.prototype.on = function (event) {
    return cordovaInstance(this, "on", {
      "observable": true,
      "clearFunction": "off",
      "clearWithArgs": true
    }, arguments);
  };
  PushObject.prototype.unregister = function () {
    return cordovaInstance(this, "unregister", {}, arguments);
  };
  PushObject.prototype.setApplicationIconBadgeNumber = function (count) {
    return cordovaInstance(this, "setApplicationIconBadgeNumber", {
      "callbackOrder": "reverse"
    }, arguments);
  };
  PushObject.prototype.getApplicationIconBadgeNumber = function () {
    return cordovaInstance(this, "getApplicationIconBadgeNumber", {}, arguments);
  };
  PushObject.prototype.finish = function (id) {
    return cordovaInstance(this, "finish", {
      "callbackOrder": "reverse"
    }, arguments);
  };
  PushObject.prototype.clearAllNotifications = function () {
    return cordovaInstance(this, "clearAllNotifications", {}, arguments);
  };
  PushObject.prototype.subscribe = function (topic) {
    return cordovaInstance(this, "subscribe", {}, arguments);
  };
  PushObject.prototype.unsubscribe = function (topic) {
    return cordovaInstance(this, "unsubscribe", {}, arguments);
  };
  var _a;
  PushObject.pluginName = "Push";
  PushObject.plugin = "phonegap-plugin-push";
  PushObject.pluginRef = "PushNotification";
  PushObject = __decorate([__metadata("design:paramtypes", [typeof (_a = typeof PushOptions !== "undefined" && PushOptions) === "function" ? _a : Object])], PushObject);
  return PushObject;
}();
export { PushObject };
