import { __decorate, __extends } from "tslib";
import { Injectable } from '@angular/core';
import { AwesomeCordovaNativePlugin, cordova } from '@awesome-cordova-plugins/core';
import * as i0 from "@angular/core";
var SplashScreen = /** @class */function (_super) {
  __extends(SplashScreen, _super);
  function SplashScreen() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  SplashScreen.prototype.show = function () {
    return cordova(this, "show", {
      "sync": true
    }, arguments);
  };
  SplashScreen.prototype.hide = function () {
    return cordova(this, "hide", {
      "sync": true
    }, arguments);
  };
  SplashScreen.ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "12.2.17",
    ngImport: i0,
    type: SplashScreen,
    deps: null,
    target: i0.ɵɵFactoryTarget.Injectable
  });
  SplashScreen.ɵprov = i0.ɵɵngDeclareInjectable({
    minVersion: "12.0.0",
    version: "12.2.17",
    ngImport: i0,
    type: SplashScreen
  });
  SplashScreen.pluginName = "SplashScreen";
  SplashScreen.plugin = "cordova-plugin-splashscreen";
  SplashScreen.pluginRef = "navigator.splashscreen";
  SplashScreen.repo = "https://github.com/apache/cordova-plugin-splashscreen";
  SplashScreen.platforms = ["Amazon Fire OS", "Android", "iOS", "Windows"];
  SplashScreen = __decorate([], SplashScreen);
  return SplashScreen;
}(AwesomeCordovaNativePlugin);
export { SplashScreen };
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.17",
  ngImport: i0,
  type: SplashScreen,
  decorators: [{
    type: Injectable
  }],
  propDecorators: {
    show: [],
    hide: []
  }
});
