import { NgModule, ErrorHandler, isDevMode } from '@angular/core';
import { Push } from '@awesome-cordova-plugins/push/ngx';
import { IonicStorageModule } from '@ionic/storage-angular';
import { RouteReuseStrategy } from '@angular/router';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { BrowserModule } from '@angular/platform-browser';
import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { LocalNotifications } from '@awesome-cordova-plugins/local-notifications/ngx';
import { Insomnia } from '@awesome-cordova-plugins/insomnia/ngx';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { RouteNotFoundComponent } from './components/route-not-found/route-not-found.component';

import { NotificationManager } from './managers/notification.manager';
import { CacheManager } from './managers/cache.manager';

import { PwaService } from './services/pwa.service';
import { GameService } from './services/game.service';
import { UserService } from './services/user.service';
import { AuthService } from './services/auth.service';
import { SongService } from './services/song.service';
import { ParamService } from './services/param.service';
import { SettingsService } from './services/settings.service';
import { ValidationService } from './services/validation.service';
import { HttpAuthInterceptorService } from './services/http-auth-interceptor.service';
import { InsertCardService } from './services/insert-card.service';
import { SentryErrorHandler } from './services/sentry.service';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';

import { PaymentService } from './services/payment.service';

import { ENV } from '../environments/environment';
import { CommonModule } from '@angular/common';

const config: SocketIoConfig = { url: ENV.SOCKET_BASE_URL, options: { withCredentials: true, transports : ['websocket'] }};

@NgModule({ declarations: [
        AppComponent,
        RouteNotFoundComponent,
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        BrowserAnimationsModule,

        IonicStorageModule.forRoot(),
        IonicModule.forRoot(),
        AppRoutingModule,
        ServiceWorkerModule.register('./ngsw-worker.json'),
        SocketIoModule.forRoot(config),
        ServiceWorkerModule.register('ngsw-worker.js', {
          enabled: !isDevMode(),
          // Register the ServiceWorker as soon as the application is stable
          // or after 30 seconds (whichever comes first).
          registrationStrategy: 'registerWhenStable:30000'
        })], providers: [
        StatusBar,
        SplashScreen,
        Geolocation,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: HTTP_INTERCEPTORS, useClass: HttpAuthInterceptorService, multi: true },
        { provide: ErrorHandler, useClass: SentryErrorHandler },
        LocalNotifications,
        Push,
        ParamService,
        AuthService,
        UserService,
        SongService,
        SettingsService,
        ValidationService,
        NotificationManager,
        CacheManager,
        GameService,
        PwaService,
        InsertCardService,
        Insomnia,
        PaymentService,
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule { }
