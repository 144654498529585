import { ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';

/*Sentry.init({
  dsn: 'https://6cf16d75af344b408604b8e4ca02f427@o382426.ingest.sentry.io/5211254'
});*/

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() { }
  handleError(error) {
    console.log("Sentry Error Handler")
    console.error(error)
    //Sentry.captureException(error.originalError || error);
  }
}
