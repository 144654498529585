<ion-header>
  <ion-toolbar mode="ios">
    <ion-buttons slot="start">
      <ion-back-button></ion-back-button>
    </ion-buttons>
    <ion-title>Not found</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding ion-text-center">
  This page was not found
</ion-content>
