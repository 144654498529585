import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { Insomnia } from '@awesome-cordova-plugins/insomnia/ngx';
import { Location } from '@angular/common';
import { Socket } from 'ngx-socket-io';

import { NotificationManager } from './managers/notification.manager';
import { CacheManager } from './managers/cache.manager';

import { SettingsService } from './services/settings.service';
import { AuthService } from './services/auth.service';
import { PwaService } from './services/pwa.service';
import { BUY_CREDITS_ROUTE, USER_DETAIL_ROUTE } from './shared/routes/user-routes';
import { AUTH_VERIFY_EMAIL_ROUTE } from './shared/routes/auth-routes';
import * as Sentry from '@sentry/browser';
import { Storage } from '@ionic/storage-angular';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  constructor(
    private router: Router,
    private location: Location,
    private platform: Platform,
    private cache: CacheManager,
    private statusBar: StatusBar,
    private pwaService: PwaService,
    private authService: AuthService,
    private push: NotificationManager,
    private splashScreen: SplashScreen,
    private settingsService: SettingsService,
    private insomnia: Insomnia,
    private socket: Socket,
    private storage: Storage,
  ) {
    this.storage.create()
    this.initializeApp()
    this.settingsService.getAll().catch(console.error);
  }


  async ngOnInit(): Promise<void> {
    console.log('this.router.url.includes(auth/reset-password', this.location.path())
    const isAuthenticated = await this.authService.checkAuthentication();
    const { currentUser } = await this.authService


    if (isAuthenticated) {
      if (currentUser['isEmailConfirmed']) {

        // Allow user to buy credits and not be redirected
        if (this.router.url != '/user/buy-credits') {
          await this.router.navigate(USER_DETAIL_ROUTE);
        }
      } else {
        // Redirect if not authenticated
        await this.router.navigate(USER_DETAIL_ROUTE);
      }

    } else {
      if (!this.location.path().includes('reset-password')) {
        // Redirect if not authenticated
        //await this.router.navigate(USER_DETAIL_ROUTE);
      }

    }

    /*
    this.insomnia.keepAwake()
      .then(() => console.log('insomnia activated'))
      .catch((error) => {
        Sentry.withScope(scope => {
          Sentry.captureException(error);
        });
      });
      */
  }

  initializeApp() {
    this.platform.ready().then(async () => {
      /*
      * App task 1 - Cache host app
      * Setting the TTL for cache and cleaning expired cache
      */
      await this.cache.clearExpired();
      this.cache.setTTL(60); // The cache will remain for 2 hours

      this.statusBar.styleDefault();
      this.splashScreen.hide();

      this.push.init();
    });
  }

  async ngOnDestroy(): Promise<void> {
    await this.cache.clearAll();
  }

}
